import toUserConverter from '@/services/toUserConverter'
import serverAPI from '@/services/serverAPI'

/**
 * @param {Vuex.Store} store
 */
const fetchAllUsers = async store => {
  const users = await serverAPI.fetchAllUsers()

  store.commit('setAllUsers', users.results)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const fetchUsers = async (store, payload) => {
  const page = payload ? payload.page : 1
  const query = payload ? payload.query : ''
  const sorting = payload ? payload.sorting : ''
  const limit = payload && payload.limit ? payload.limit : store.limit

  const users = await serverAPI.fetchUsers(page, limit, query, sorting)

  store.commit('setUsers', users.results)
  store.commit('setUsersCount', users.count)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const fetchMentionableUsers = async (store, payload) => {
  const { teamId } = payload
  const users = await serverAPI.fetchMentionableUsers(teamId)

  store.commit('setMentionableUsers', users)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const fetchUsersByTeam = async (store, payload) => {
  const { teamId, page = 1, query = '', sorting = '' } = payload
  const users = await serverAPI.fetchUsersByTeam(
    teamId,
    page,
    store.limit,
    query,
    sorting
  )

  store.commit('setUsersByTeam', users.results)
  store.commit('setUsersByTeamCount', users.count)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const fetchUsersByVendor = async (store, payload) => {
  const { vendorId, page = 1, query = '', sorting = '' } = payload
  const users = await serverAPI.fetchUsersByVendor(
    vendorId,
    page,
    store.limit,
    query,
    sorting
  )

  store.commit('setUsersByVendor', users.results)
  store.commit('setUsersByVendorCount', users.count)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const fetchRegistrations = async (store, payload) => {
  const page = payload ? payload.page : 1
  const query = payload ? payload.query : ''
  const limit = payload && payload.limit ? payload.limit : store.limit
  const sorting = payload ? payload.sorting : ''

  const registrations = await serverAPI.fetchRegistrations(
    page,
    limit,
    query,
    sorting
  )

  store.commit('setRegistrations', registrations.results)
  store.commit('setRegistrationsCount', registrations.count)
}

/**
 * @param {Vuex.Store} store
 */
const fetchTeams = async store => {
  const teams = await serverAPI.fetchTeams()

  store.commit('setTeams', teams)
}

/**
 * @param {Vuex.Store} store
 */
const fetchVendors = async store => {
  const vendors = await serverAPI.fetchVendors()

  store.commit('setVendors', vendors)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const fetchVendorById = async (store, payload) => {
  const { vendorId } = payload
  const vendor = await serverAPI.fetchVendorById(vendorId)

  store.commit('setActiveVendor', vendor)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const fetchAssignableVendorsByTeam = async (store, payload) => {
  const { teamId } = payload
  const vendors = await serverAPI.fetchAssignableVendorsByTeam(teamId)

  store.commit('setVendorsByTeam', vendors)
}

/**
 * @param   {Vuex.Store} store
 * @param   {object}     payload
 *
 * @returns {object[]}
 */
const addUsers = async (store, payload) => {
  for (const invitation of payload.invitations) {
    const { email, roleId, teamIds } = invitation

    await serverAPI.addUser(email, roleId, teamIds)

    // store.commit('addUser', user)
  }

  return []
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const inviteUser = async (store, payload) => {
  const { email, teamId } = payload

  await serverAPI.inviteUser(email, teamId)

  await store.dispatch('fetchUsersByTeam', { teamId, page: 1 })
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const updateUser = async (store, payload) => {
  const { userId, email, isActive, roleId, query, page } = payload

  await serverAPI.updateUser(userId, email, isActive, roleId)

  await store.dispatch('fetchUsers', { page, query })
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const updateVendorTeams = async (store, payload) => {
  const { vendorId, teamIds } = payload

  await serverAPI.updateVendorTeams(vendorId, teamIds)

  await store.dispatch('fetchVendorById', { vendorId })
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const changeUserTeams = async (store, payload) => {
  const { userId, teamIds } = payload
  await serverAPI.changeUserTeams(userId, teamIds)

  await store.dispatch('fetchUsers')
}

/**
 * @param   {Vuex.Store} store
 * @param   {object}     payload
 *
 * @returns {object}
 */
const updateMembershipForUser = async (store, payload) => {
  const { teamId, userId, canManageUsers } = payload
  const user = await serverAPI.updateMembershipForUser(
    teamId,
    userId,
    canManageUsers
  )

  store.commit('updateUserByTeam', toUserConverter.from.user(user))

  return user
}

/**
 * @param   {Vuex.Store} store
 * @param   {object}     payload
 *
 * @returns {object}
 */
const assignVendorUserToCompany = async (store, payload) => {
  const { email, vendorCompanyId } = payload
  await serverAPI.assignVendorUserToCompany(email, vendorCompanyId)

  await store.dispatch('fetchRegistrations')
}

/**
 * @param   {Vuex.Store} store
 * @param   {object}     payload
 *
 * @returns {object}
 */
const deleteVendorRegistration = async (store, payload) => {
  const { email } = payload
  await serverAPI.deleteVendorRegistration(email)

  store.commit('deleteVendorRegistration', email)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const deleteUser = async (store, payload) => {
  await serverAPI.deleteUser(payload.userId)

  store.commit('deleteUser', payload.userId)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const deleteUserFromTeam = async (store, payload) => {
  await serverAPI.deleteUserFromTeam(payload.teamId, payload.userId)

  store.commit('deleteUserFromTeam', payload.userId)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const deleteUserFromVendor = async (store, payload) => {
  await serverAPI.deleteUserFromVendor(payload.vendorId, payload.userId)

  store.commit('deleteUserFromVendor', payload.userId)
}

/**
 */
const exportUsers = async () => {
  await serverAPI.exportUsers()
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const exportTeamUsers = async (store, payload) => {
  await serverAPI.exportTeamUsers(payload.teamId, payload.teamName)
}

const actions = {
  fetchAllUsers,
  fetchUsers,
  fetchTeams,
  fetchVendors,
  fetchUsersByTeam,
  fetchUsersByVendor,
  fetchVendorById,
  fetchMentionableUsers,
  fetchRegistrations,
  fetchAssignableVendorsByTeam,
  addUsers,
  inviteUser,
  updateUser,
  updateMembershipForUser,
  updateVendorTeams,
  assignVendorUserToCompany,
  deleteVendorRegistration,
  deleteUser,
  deleteUserFromTeam,
  deleteUserFromVendor,
  changeUserTeams,
  exportUsers,
  exportTeamUsers
}

export default actions
