// ////////////////////////////////////////////////////
// Getters (ROOT)
// ////////////////////////////////////////////////////

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getCognitoUserData = state => state.cognitoUserData

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getRoute = state => state.route

/**
 * @param   {object}  state
 *
 * @returns {boolean}
 */
const getIsNotFound = state => state.isNotFound

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getBrowzwearAPI = state => state.browzwearAPI

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const isDarkMode = state => state.isDarkMode

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getActiveTeam = state => state.activeTeam

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getSeasons = state => state.seasons

/**
 * @param   {object} state
 * @param   {object} getters
 *
 * @returns {object}
 */
const getActiveWorkspace = (state, getters) => {
  return (
    state.activeWorkspace ||
    getters.getCognitoUserData.workspaces.find(option => {
      return localStorage.getItem('activeWorkspace') === option.code
    }) ||
    undefined
  )
}

/**
 * @returns {string}
 */
const getActiveWorkspaceCode = () => {
  return localStorage.getItem('activeWorkspace')
}

const getters = {
  getCognitoUserData,
  getIsNotFound,
  getBrowzwearAPI,
  isDarkMode,
  getActiveTeam,
  getActiveWorkspace,
  getActiveWorkspaceCode,
  getRoute,
  getSeasons
}

export default getters
